let data = {};

// if (process.env.NODE_ENV === "development")
//   data = {
//     company_marketing_data_use: "true",
//     company_marketing_data: ["telefono numeris"],
//     company_marketing_data_agreement: ["Duomenys rasti viešoje prieigoje"],
//     company_marketing_data_agreement_form: [],
//     company_marketing_data_agreement_mechanism: "false",
//     company_marketing_data_agreement_validation: "false",
//     company_marketing_data_agreement_prove: "false",
//     company_marketing_data_agreement_prove_data: [],
//     company_marketing_data_storage_terms: "true",
//     company_marketing_minors_data: "true",
//     company_marketing_minors_check: "true",
//     company_marketing_minors_parent_agreement: "true",
//     company_marketing_procedures: "true",
//     company_marketing_data_decisions: ["Vadovas"],
//   };

export default data;
