<template>
  <product-step-wrapper
    v-if="showQuestions"
    :questions="questions"
    :default-model="defaultModel"
    :submit-function="saveData"
    :on-success="onSubmit"
    skip-intro
  />
  <th-card v-else>
    <div class="th-py-8">
      <h5 class="th-m-0 body2">
        Ar renkate asmens duomenis tiesioginės rinkodaros tikslais?
      </h5>
      <radio-input
        :input="companyMarketingDataUseQuestion"
        v-model="defaultModel.company_marketing_data_use"
      />
    </div>
    <div class="text-right">
      <v-btn color="primary" @click="next" class="th-mt-24" :loading="loading">
        {{
          noFutherQuestions ? $translation.t("submit") : $translation.t("next")
        }}
      </v-btn>
    </div>
  </th-card>
</template>

<script>
import ProductStepWrapper from "./ProductStepWrapper";
import defaultModel from "../../../data/questionnaire/business/businessMarketing";
import ThCard from "../../../components/GeneralComponents/ThCard";
import RadioInput from "../../../components/FormFields/RadioInput";
import { isFalse } from "../../../utils/questionGeneratorHelpers";
import { questionByType } from "../../../utils/service/Questionnaire";
import { mapMutations } from "vuex";

const QUESTIONS_TYPE = "marketing";

export default {
  name: "MarketingQuestions",
  components: { RadioInput, ThCard, ProductStepWrapper },
  props: {
    value: {},
    saveData: {
      type: Function,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      questions: questionByType(QUESTIONS_TYPE, this.$route.params.locale),
      defaultModel,
      showQuestions: false,
      loading: false,
    };
  },
  async beforeCreate() {
    this.$store.commit("questionnaire/RESET_CURRENT_QUESTION");
  },
  created() {
    this.defaultModel = this.value || this.defaultModel;

    this.$route.params.type = QUESTIONS_TYPE;

    this.$set(this.defaultModel, "company_marketing_data_use", "true");

    this.questions.forEach((q, qIndex) => {
      this.SET_CURRENT_QUESTION_STATUS({
        q: qIndex + 1,
        visited: true,
      });
    });
  },
  computed: {
    noFutherQuestions() {
      return isFalse(this.defaultModel.company_marketing_data_use);
    },
    companyMarketingDataUseQuestion() {
      return {
        default: "true",
        values: {
          true: "Taip",
          false: "Ne",
        },
      };
    },
  },
  methods: {
    async next() {
      if (isFalse(this.defaultModel.company_marketing_data_use)) {
        this.loading = true;
        await this.saveData(this.defaultModel);
        await this.onSubmit();
        this.loading = false;
      } else {
        this.showQuestions = true;
      }
    },
    ...mapMutations("questionnaire", ["SET_CURRENT_QUESTION_STATUS"]),
  },
};
</script>

<style lang="scss" scoped></style>
